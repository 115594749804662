import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { buttonCss } from "app/components/Button";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import PropTypes from "prop-types";
import PhotoCropModal from "app/components/PhotoCropModal";
import env from "helpers/env";

const Wrapper = styled.div`
  margin-top: 25px;
`;

const AvatarContainer = styled.div`
  width: 164px;
  height: 164px;
  border-radius: 50%;
  overflow: hidden;
`;

const Avatar = styled.img`
  height: 100%;
`;

const Upload = styled.label`
  ${buttonCss};
  cursor: pointer;
  position: absolute;
  height: 40px;
  width: 40px;
  padding: 15px;
  border-radius: 50%;
  font-size: 28px;
  top: 120px;
  margin-left: 120px;
`;

const UploadInput = styled.input`
  display: none;
`;

const ProfilePhoto = ({ providerData = [] }) => {
  const [photoToUpload, setPhotoToUpload] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const [imageWidth, setImageWidth] = useState(null);
  const userPublic = useSelector(({ user }) => user.public);
  const providerDataWithPhoto = providerData.find(data => !!data.photoURL);
  const imgRef = useRef();
  const fileInputRef = useRef();

  const closeModal = () => {
    setPhotoToUpload(null);
    fileInputRef.current.value = null;
  };

  return (
    <Wrapper>
      <Flex flexDirection="column" alignItems="center" position="relative">
        <AvatarContainer>
          <Avatar
            alt="Avatar"
            ref={imgRef}
            src={
              userPublic.profile_pic ||
              (providerDataWithPhoto &&
                `${providerDataWithPhoto.photoURL}?height=500&width=500`) ||
              `${env("PUBLIC_ASSET_PATH")}/default-avatar.png?w=240`
            }
            onError={() => {
              imgRef.current.src = `${env(
                "PUBLIC_ASSET_PATH"
              )}/default-avatar.png?w=240`;
            }}
          />
          <Upload htmlFor="uploadPhoto">
            <Div mt="1px" ml="1px">
              +
            </Div>
          </Upload>
          <UploadInput
            id="uploadPhoto"
            type="file"
            ref={fileInputRef}
            accept="image/*"
            onChange={evt => {
              evt.stopPropagation();
              evt.preventDefault();
              const file = evt.target.files[0];
              const reader = new FileReader();
              reader.onload = () => {
                const image = new Image();
                image.src = reader.result;
                image.onload = () => {
                  setImageWidth(image.width);
                  setImageHeight(image.height);
                  setPhotoToUpload(reader.result);
                };
              };
              if (file) {
                reader.readAsDataURL(file);
              }
            }}
          />
        </AvatarContainer>
      </Flex>
      <PhotoCropModal
        isOpen={!!photoToUpload}
        closeModal={closeModal}
        photo={photoToUpload}
        imageHeight={imageHeight}
        imageWidth={imageWidth}
      />
    </Wrapper>
  );
};

ProfilePhoto.defaultProps = {
  providerData: [],
};

ProfilePhoto.propTypes = {
  providerData: PropTypes.instanceOf(Array),
  userPublic: PropTypes.shape({}).isRequired,
};

export default ProfilePhoto;
