import React, { useState, useRef } from "react";
import styled, { css } from "styled-components";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Flex from "app/components/Flex";
import Loader from "app/components/Loader";
import Button from "app/components/Button";
import Alert from "app/components/Alert";
import PropTypes from "prop-types";
import useSetProfilePhoto from "hooks/Challenges/useSetProfilePhoto";
import Modal from "../Modal";

const ButtonContainer = styled(Flex)`
  justify-content: flex-end;
`;

const button = css`
  width: 150px;
`;

const StyledButton = styled(Button)`
  ${button}
  margin-right: 10px;
`;

const PhotoCropModal = ({
  closeModal,
  imageHeight,
  imageWidth,
  isOpen,
  photo,
}) => {
  const [error, setError] = useState(null);
  const cropperRef = useRef();
  const [
    setProfilePhoto,
    { loading: isSetProfilePhotoLoading },
  ] = useSetProfilePhoto({
    onCompleted: () => {
      closeModal();
    },
    onError: () => {
      setError("Something went wrong. Please try again.");
    },
  });

  const uploadPhoto = async () => {
    setError(null);

    cropperRef.current.getCroppedCanvas().toBlob(blob => {
      if (blob.size > 4999999) {
        setError("The file you are trying to upload is too large.");
        return;
      }
      setProfilePhoto({
        variables: {
          photoFile: blob,
        },
      });
    });
  };

  const determineModalDimensions = (imgHeight, imgWidth) => {
    let height;

    if (imgHeight > 400) {
      height = 400;
    } else if (imgHeight < 100) {
      height = 100;
    } else {
      height = imgHeight;
    }
    // scale width to modified height if it has been modified
    const width = (imgWidth * height) / imgHeight;

    return {
      height,
      width,
    };
  };

  return (
    <Modal
      isOpen={isOpen}
      onBackgroundClick={closeModal}
      onEscapeKeydown={closeModal}
    >
      <Cropper
        ref={cropperRef}
        src={photo}
        style={determineModalDimensions(imageHeight, imageWidth)}
        minCropBoxWidth={50}
        minCropBoxHeight={50}
        aspectRatio={1}
        guides={false}
        viewMode={1}
        modal
      />
      <Flex flexDirection="column" mt="20px">
        {error && (
          <Alert variant="danger" closeAlert={() => setError(null)}>
            {error}
          </Alert>
        )}
        <ButtonContainer>
          <StyledButton
            variant="secondary"
            disabled={isSetProfilePhotoLoading}
            onClick={closeModal}
          >
            Cancel
          </StyledButton>
          <StyledButton
            disabled={isSetProfilePhotoLoading}
            type="button"
            onClick={uploadPhoto}
          >
            {!isSetProfilePhotoLoading && "Upload"}
            {isSetProfilePhotoLoading && <Loader width="20" />}
          </StyledButton>
        </ButtonContainer>
      </Flex>
    </Modal>
  );
};

PhotoCropModal.defaultProps = {
  imageHeight: null,
  imageWidth: null,
  isOpen: false,
  photo: null,
};

PhotoCropModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  imageHeight: PropTypes.number,
  imageWidth: PropTypes.number,
  isOpen: PropTypes.bool,
  photo: PropTypes.string,
};

export default PhotoCropModal;
