import { SET_PROFILE_PHOTO } from "graphql/mutations";
import { useMutation } from "@apollo/client";

export default options => {
  const [setProfilePhoto, setProfilePhotoResults] = useMutation(
    SET_PROFILE_PHOTO,
    options
  );

  return [
    ({ variables }) => {
      return setProfilePhoto({
        variables,
      });
    },
    setProfilePhotoResults,
  ];
};
