import React from "react";
import { PageBody } from "app/components/RouteComponents";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import Flex from "app/components/Flex";
import { H2 } from "app/components/Typography";
import PropTypes from "prop-types";
import DancePreferences from "./DancePreferences";
import ProfilePhoto from "../Settings/ProfilePhoto";
import { AccountBodyWrapper } from "../AccountBodyWrapper";

const Preferences = ({ providerData }) => (
  <AccountBodyWrapper>
    <Helmet title="Preferences | Online Dance Classes and Tutorials" />
    <PageBody>
      <ProfilePhoto providerData={providerData} />
      <Flex flexDirection="column" textAlign="center" my={4}>
        <H2>My Dance Preferences</H2>
      </Flex>
      <Flex justifyContent="center">
        <DancePreferences />
      </Flex>
    </PageBody>
  </AccountBodyWrapper>
);

const mapStateToProps = ({ auth, user }) => ({
  auth,
  userPublic: user.public,
  userPrivate: user.private,
  providerData: auth.providerData,
});

Preferences.defaultProps = {
  providerData: null,
};

Preferences.propTypes = {
  providerData: PropTypes.instanceOf(Array),
  userPublic: PropTypes.shape({}).isRequired,
  userPrivate: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps)(Preferences);
